@import url("/src/vendor/fonts/fonts.css");
@import url("/src/vendor/normalize.css");

* {
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}

.winn-page {
  max-width: 1440px;
  margin: 0 auto;
  text-align: left;
  font-family: "Poppins", "Arial", sans-serif;
  background-color: #fefefe;
}

h1,
h2 {
  font-size: 35px;
  line-height: 52px;
  margin: 0;
  font-weight: 400;
}

p,
a,
li {
  margin: 0;
  color: #030303;
  text-decoration: none;
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.t-35 {
  font-size: 35px;
  line-height: 52px;
}

.t-25 {
  font-size: 25px;
  line-height: 37px;
}

.t-20 {
  font-size: 20px;
  line-height: 30px;
}

.t-15 {
  font-size: 15px;
  line-height: 22px;
}

.text-center {
  text-align: center;
}

.col-white {
  color: #fefefe;
}

.col-green {
  color: #47591d;
}

.fw-500 {
  font-weight: 500;
}

.pad {
  padding: 100px 160px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.winn-list {
  display: flex;
  flex-direction: column;
  gap: 5px;
  padding-left: 20px;
  list-style: disc;
}

@media (max-width: 1100px) {
  .pad {
    padding: 60px;
  }
}

@media (max-width: 749px) {
  .pad {
    padding: 40px 10px;
  }

  h1,
  h2,
  .t-35 {
    font-size: 25px;
    line-height: 30px;
  }

  .t-20,
  .t-25 {
    font-size: 15px;
    line-height: 22px;
  }
}
