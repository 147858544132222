.winn-main__cover {
  background-color: #652a22;
  height: 404px;
  display: flex;
  flex-direction: column;
  padding-left: 230px;
  position: relative;
}

.winn-country__cover {
  height: 203px;
}

.winn-main__drop-down {
  width: 230px;
  position: absolute;
  left: 0;
  flex-shrink: 0;
  z-index: 2;
}

.winn-main__drop-list {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.winn-main__drop-item,
.winn-main__drop-btn {
  height: 102px;
  border: 1px solid #652a22;
  margin-top: -1px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f3e6e0;
  font-size: 25px;
  line-height: 37px;
  text-align: center;
}

.winn-main__drop-btn img {
  cursor: pointer;
}

.rotate {
  transform: rotate(180deg);
}

.winn-main__body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 20px;
  flex-grow: 1;
  padding: 10px 120px;
}

.winn-main__header {
  font-size: 35px;
  line-height: 52px;
  max-width: 727px;
  font-weight: 500;
}

.winn-country__header {
  max-width: 100%;
}

.winn-main__text {
  max-width: 955px;
}

.winn-main__link {
  background-color: #a5cee2;
  width: 338px;
  height: 68px;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 1100px) {
  .winn-main__cover {
    padding-left: 130px;
    height: 356px;
  }

  .winn-country__cover {
    height: 179px;
  }

  .winn-main__drop-down {
    width: 130px;
  }

  .winn-main__drop-item,
  .winn-main__drop-btn {
    height: 90px;
    font-size: 18px;
    line-height: 25px;
  }

  .winn-main__header {
    font-size: 25px;
    line-height: 37px;
  }

  .winn-main__body {
    padding: 10px 40px;
  }

  .winn-main__link {
    height: 43px;
    width: 193px;
    font-size: 15px;
    line-height: 22px;
  }
}

@media (max-width: 749px) {
  .winn-main__drop-down {
    display: none;
  }

  .winn-main__cover {
    padding: 20px 17px;
    min-height: 321px;
    height: auto;
  }

  .winn-country__cover {
    min-height: 174px;
  }

  .winn-main__body {
    padding: 0;
    align-items: center;
    gap: 15px;
  }

  .winn-main__header,
  .winn-main__text {
    text-align: center;
    max-width: 500px;
  }

  .winn-main__body p {
    text-align: center;
  }
}
