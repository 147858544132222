.winn-training {
background-color: #F3E6E0;
}

@media (max-width: 749px) {
  .winn-training {
    margin: 40px auto 0;
    gap: 15px;
  }
}
