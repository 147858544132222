.winn-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(2, 1fr);
  gap: 34px;
  margin-top: 70px;
  margin-bottom: 30px;
}

.winn-grid__item {
  display: flex;
  flex-direction: column;
  gap: 10px;
  overflow: hidden;
}

.text {
  background-color: #898C31;
  margin-top: 40px;
  justify-content: center;
  align-items: start;
  padding: 15px;
  font-size: 18px;
  line-height: 27px;
}

.winn-grid img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

@media (max-width: 1100px) {
  .winn-grid {
    gap: 15px;
  }
}

@media (max-width: 749px) {
  .winn-grid {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(3, 1fr);
  }

  .text {
    margin-top: 32px;
  }
}

@media (max-width: 500px) {
  .winn-grid {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(6, 1fr);
    margin-top: 50px;
    margin-bottom: 0;
  }

  .text {
    margin-top: 20px;
  }
}
