.winn-top__container {
  background-color: #f3e6e0;
  border: 1px solid #652a22;
  min-height: 102px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: 0 160px;
}

.winn-date {
  position: absolute;
  left: 20px;
  width: 100px;
  text-align: center;
}

.winn-top__text {
  font-size: 40px;
  line-height: 60px;
  font-weight: 500;
}

.burger-menu,
.burger__close-btn {
  display: none;
}

.winn-burger__icon {
 cursor: pointer;
}

.burger-menu__nav {
  position: fixed;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background: #F3E6E0;
  transition: left 0.3s ease-in-out;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
}

.active {
  left: 0;
}

.burger-menu__nav-container {
  display: flex;
  flex-direction: column;
  text-align: center;
  gap: 20px;
}

.burger__close-btn {
  position: absolute;
  top: 20px;
  right: 20px;
  display: none;
  cursor: pointer;
}

.active .burger__close-btn {
  display: block;
}

.winn-top__mob-text {
  display: none;
}

@media (max-width: 1100px) {
  .winn-top__text {
    font-size: 30px;
    line-height: 40px;
  }
}

@media (max-width: 749px) {
  .winn-top__container {
    min-height: 50px;
    padding: 0 60px;
  }

  .winn-top__text {
    display: none;
  }

  .winn-date {
    position: static;
    width: 100%;
  }

  .burger-menu {
    display: flex;
    position: absolute;
    left: 0;
    width: 50px;
    height: 50px;
    background-color: #652a22;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .winn-top__mob-text {
    display: block;
    background-color: #F3E6E0;
    border: 1px solid #652A22;
    font-size: 30px;
    line-height: 45px;
    text-align: center;
    padding: 10px 25px;
  }
}
