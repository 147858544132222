.winn-reviews {
  padding: 60px 0;
}

.winn-reviews__header {
  margin-bottom: 40px;
}

.winn-reviews__img-container {
  display: flex;
  gap: 20px;
  align-items: center;
}
.winn-swiper {
  padding: 80px 20px 50px;
  max-width: 740px;
}

.winn-reviews .swiper-button-prev {
  background: #f3e6e0;
  color: black;
  margin: 0;
  position: absolute;
  width: 55px;
  height: 55px;
  border-radius: 50%;
  padding: 2px;
  top: 0;
  left: 40%;
  z-index: 10;
  cursor: pointer;
}

.winn-reviews .swiper-button-next {
  background: #f3e6e0;
  color: black;
  margin: 0;
  position: absolute;
  width: 55px;
  height: 55px;
  border-radius: 50%;
  top: 0;
  right: 40%;
  z-index: 10;
  cursor: pointer;
}

.winn-reviews__slide {
  background-color: #652a22;
  min-height: 246px;
  padding: 50px 62px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  height: auto;
}

.swiper-pagination-bullet {
  width: 150px;
  height: 5px;
  border-radius: 0;
  background: rgba(243, 230, 224, 0.8);
  margin: 0 !important;
}

.swiper-pagination-bullet-active {
  background-color: #652a22;
}

@media (max-width: 1100px) {
  .winn-reviews {
    padding: 30px 0;
  }
}
@media (max-width: 1100px) {
  .winn-reviews {
    padding: 30px 0;
  }

  .winn-reviews .swiper-button-next, .winn-reviews .swiper-button-prev {
    display: none;
  }

  .winn-swiper {
    padding: 0 10px 50px;
    max-width: 387px;
  }

  .swiper-pagination-bullet {
    width: 90px;
  }

  .winn-reviews__slide {
    background-color: #652a22;
    min-height: 380px;
    padding: 40px 10px;
    flex-direction: column-reverse;
    gap: 20px;
    justify-content: center;
  }

  .winn-reviews__img-container {
    flex-direction: column;
    align-items: start;
  }

  .winn-reviews__img-container img {
    width: 150px;
    height: 154px;
  }
}
