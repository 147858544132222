.winn-header__link-container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 30px;
  position: relative;
  z-index: 3;
  background-color: #c7a6a1;
  height: 51px;
}

.winn-header__link {
  font-size: 18px;
  line-height: 27px;
}

@media (max-width: 1100px) {
  .winn-header__link {
    font-size: 13px;
    line-height: 17px;
  }

  .winn-header__link-container {
    gap: 20px;
  }
}

@media (max-width: 749px) {
  .winn-header__link-container {
    display: none;
  }
}
