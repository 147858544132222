.winn-form {
  min-height: 865px;
  background-color: #898c31;
  display: flex;
  justify-content: center;
}

.winn-form__form {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 35px;
  position: relative;
  margin-top: 30px;
}

.winn-form__input {
  border: none;
  border-bottom: 2px solid rgba(238, 238, 238, 0.8);
  min-height: 68px;
  padding: 0 30px 0 0;
  background-color: transparent;
  font-size: 40px;
  line-height: 60px;
  font-weight: 500;
  width: 100%;
  color: #fefefe;
}

.winn-form__input:focus {
  outline: none;
}

.winn-form__input::placeholder {
  color: rgba(246, 246, 246, 0.6);
}

.winn-form__btn {
  position: absolute;
  right: 0;
  bottom: 10px;
  background-color: transparent;
  border: none;
  padding: 0;
  cursor: pointer;
}

.winn-popup-form__body {
  width: 100%;
  max-width: 473px;
  min-height: 318px;
  position: relative;
  background: #fefefe;
  padding: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
}

.winn-popup-form__close {
  position: absolute;
  z-index: 1;
  right: 20px;
  top: 20px;
  cursor: pointer;
}

@media (max-width: 749px) {
  .winn-form {
    min-height: 565px;
    padding: 40px 10px;
  }

  .winn-form__body {
    max-width: 550px;
  }

  .winn-form__input {
    font-size: 20px;
    line-height: 30px;
    min-height: 37px;
  }

  .winn-form__btn {
    width: 20px;
    height: 20px;
  }

  .winn-form__btn img {
    width: 100%;
    height: auto;
  }
}
