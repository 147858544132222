.winn-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(26, 26, 26, 0.8);
  display: flex;
  align-items: start;
  justify-content: center;
  z-index: 9999;
  padding: 200px 25px 25px;
}

.winn-popup-cookie {
  position: fixed;
  bottom: 0;
  right: 0;
  width: 100%;
  max-width: 496px;
  min-height: 300px;
  z-index: 3;
  background: #fefefe;
  padding: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
}

.winn-popup__body {
  width: 100%;
  max-width: 673px;
  min-height: 418px;
  position: relative;
  background: #fefefe;
  padding: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
}

.winn-popup__form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

.winn-popup__cookie-form {
  align-items: start;
}

.winn-popup__cookie-header {
  font-size: 25px;
  line-height: 37px;
  font-weight: 500;
}

.winn-popup__text {
  font-size: 18px;
  line-height: 27px;
  max-width: 455px;
}

.winn-popup__cookie-text {
  font-size: 13px;
  line-height: 20px;
}

.winn-popup__btn {
  width: 100%;
  max-width: 251px;
  height: 62px;
  cursor: pointer;
  background: #A5CEE2;
  border: none;
  text-align: center;
  border-radius: 30px;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  text-transform: uppercase;
}

.winn-popup__btn-container {
  display: flex;
  width: 100%;
  gap: 20px;
}

.winn-popup__btn-cookie {
  width: 100%;
  height: 47px;
  cursor: pointer;
  background: #A5CEE2;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.3s;
  border-radius: 30px;
  flex-grow: 1;
  text-transform: uppercase;
}

.winn-popup__btn-agree {
  flex-grow: 0;
  max-width: 126px;
}

.disabled {
  display: none;
}

@media (max-width: 749px) {
  .winn-popup__header {
    font-size: 34px;
    line-height: 45px;
  }

  .winn-popup__body {
    padding: 20px;
  }
}

@media (max-width: 480px) {
  .winn-popup__btn-container {
    flex-direction: column;
    align-items: center;
  }

  .winn-popup__btn-agree {
    max-width: 100%;
  }

  .winn-popup-cookie {
    padding: 20px;
  }
}
