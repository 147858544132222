.winn-casinos__header {
  display: none;
}

.winn-casinos__list {
  display: flex;
  flex-direction: column;
  gap: 40px;
}

.winn-casinos__item {
  display: flex;
  flex-direction: column;
  gap: 15px;
}
@media (max-width: 1100px) {
}

@media (max-width: 749px) {
  .winn-casinos__item {
    gap: 5px;
  }

  .winn-casinos__list {
    gap: 20px;
  }
  
}
