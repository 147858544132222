.winn-destinations {
  background-color: #f3e6e0;
  height: 50px;
  display: none;
}

.winn-destinations__swiper {
  width: 100%;
  height: 100%;
  max-width: 1121px;
  margin: 0 auto;
  padding: 0 30px;
  border: 1px solid #652a22;
}

.winn-destinations__swiper .swiper-wrapper {
  justify-content: space-between;
}

.winn-destinations__swiper-slide {
  display: flex;
  justify-content: center;
  align-items: center;
  border-right: 1px solid #652a22;
  border-left: 1px solid #652a22;
  padding: 0 30px;
}

.winn-destinations__link {
  font-size: 16px;
  line-height: 20px;
}

.winn-destinations .swiper-button-prev {
  background: url('/src/images/arrow-prev.svg') center / contain no-repeat;
  position: absolute;
  width: 30px;
  height: 30px;
  top: 60%;
  left: 0;
  z-index: 10;
  cursor: pointer;
}

.winn-destinations .swiper-button-next {
  background: url('/src/images/arrow-next.svg') center / contain no-repeat;
  position: absolute;
  width: 30px;
  height: 30px;
  top: 60%;
  right: 0;
  z-index: 10;
  cursor: pointer;
}

.winn-destinations .swiper-button-next::after, .winn-destinations .swiper-button-prev::after {
  content: none;
}

@media (max-width: 749px) {
  .winn-destinations {
    height: 50px;
    display: flex;
  }
}
